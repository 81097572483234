import clsx from 'clsx';
import {CSSProperties, PointerEventHandler, useCallback, useEffect, useMemo, useReducer, useRef} from 'react';

import classes from './index.module.scss';

interface Ripple {
    id: number,
    x: number,
    y: number,
    size: number,
    starting: boolean,
    canceled: boolean,
    ending: boolean,
    finished: boolean,
    renderedOnceTimeoutID: ReturnType<typeof setTimeout>,
    startTimeoutID: ReturnType<typeof setTimeout>,
    endTimeoutID: ReturnType<typeof setTimeout>,
    renderedOnce: boolean,
}

export interface RippleProps {
    startAnimationDuration?: number,
    cancelAnimationDuration?: number,
    focusAnimationDuration?: number,
    endAnimationDuration?: number,
    disabled?: boolean,
    color?: 'white' | 'black',
    size?: number,
}

export function useRipple({
    startAnimationDuration = 400,
    cancelAnimationDuration = 100,
    focusAnimationDuration = 800,
    endAnimationDuration = 300,
    disabled,
    color,
    size: sizeProp,
}: RippleProps) {
    const ripples = useRef<Ripple[]>([]);
    const pointerRipple = useRef<Map<number, Ripple>>(new Map());
    const [shouldRerenderRipples, renderRipples] = useReducer(x => x + 1, 0);

    const rippleElements = useMemo(() => (
        ripples.current.map(({id, size, x, y, starting, finished, canceled, ending, renderedOnce}, index) => {
            const style: CSSProperties = {
                display: finished ? 'none' : 'block',
                width: `${size}px`,
                height: `${size}px`,
                top: y,
                left: x,
            };
            const innerStyle = {
                animationDuration: focusAnimationDuration + 'ms',
            };

            if (!finished) {
                if (renderedOnce) {
                    if (starting) {
                        style.transitionDuration = startAnimationDuration + 'ms';
                    }
                    if (canceled) {
                        style.transitionDuration = cancelAnimationDuration + 'ms';
                    }
                    if (ending) {
                        style.transitionDuration = endAnimationDuration + 'ms';
                    }
                }
                else {
                    ripples.current[index].renderedOnceTimeoutID = setTimeout(() => {
                        ripples.current[index].renderedOnce = true;
                        renderRipples();
                    });
                }
            }

            return (
                <span
                    key={`ripple-${id}`}
                    className={clsx({
                        [classes['ripple']]: true,
                        [classes[`ripple--${color || 'black'}`]]: true,
                        [classes['ripple--focus']]: !finished && renderedOnce && !starting && !ending && !canceled,
                        [classes['ripple--canceled']]: !finished && renderedOnce && canceled,
                        [classes['ripple--starting']]: !finished && renderedOnce && starting,
                        [classes['ripple--ending']]: !finished && renderedOnce && ending,
                    })}
                    style={style}
                >
                    <span className={classes['ripple__inner']} style={innerStyle} />
                </span>
            );
        })
    ), [shouldRerenderRipples, focusAnimationDuration, startAnimationDuration, cancelAnimationDuration, endAnimationDuration]);

    const findFreeRipple = useCallback(() => {
        for (const ripple of ripples.current) {
            if (ripple.finished) {
                return ripple;
            }
        }

        const newRipple: Ripple = {
            id: ripples.current.length,
            x: 0,
            y: 0,
            size: 0,
            starting: false,
            canceled: false,
            ending: false,
            finished: true,
            renderedOnceTimeoutID: undefined,
            endTimeoutID: undefined,
            startTimeoutID: undefined,
            renderedOnce: false,
        };
        ripples.current.push(newRipple);

        return newRipple;
    }, []);

    const endRipple = useCallback((pointerID: number, cancel: boolean) => {
        const ripple = pointerRipple.current.get(pointerID);

        if (!ripple || ripple.finished || ripple.ending) {
            return;
        }

        pointerRipple.current.delete(pointerID);
        ripple.starting = false;
        ripple.ending = true;
        ripple.canceled = cancel;
        clearTimeout(ripple.startTimeoutID);
        clearTimeout(ripple.renderedOnceTimeoutID);
        ripple.endTimeoutID = setTimeout(() => {
            ripple.endTimeoutID = undefined;
            ripple.renderedOnce = false;
            ripple.ending = false;
            ripple.finished = true;

            renderRipples();
        }, endAnimationDuration);

        renderRipples();
    }, [endAnimationDuration]);
    const startRippleAt = useCallback(({pointerID, x, y, parent}: {
        pointerID: number,
        x: number,
        y: number,
        parent: HTMLElement,
    }) => {
        if (disabled) {
            return;
        }

        const rect = parent.getBoundingClientRect();
        const size = sizeProp || Math.max(rect.width, rect.height);
        const ripple = findFreeRipple();

        ripple.x = x - window.scrollX - size / 2 - rect.left;
        ripple.y = y - window.scrollY - size / 2 - rect.top;
        ripple.size = size;
        ripple.starting = true;
        ripple.finished = false;
        ripple.ending = false;
        ripple.canceled = false;

        endRipple(pointerID, true);
        pointerRipple.current.set(pointerID, ripple);

        renderRipples();
        ripple.startTimeoutID = setTimeout(() => {
            ripple.startTimeoutID = undefined;
            ripple.starting = false;

            renderRipples();
        }, startAnimationDuration);
    }, [disabled, sizeProp, startAnimationDuration, endRipple]);

    const onPointerDown = useCallback<PointerEventHandler<HTMLElement>>((event) => {
        if (event.pointerType === 'mouse' && event.button !== 0) {
            return;
        }

        startRippleAt({
            pointerID: event.pointerId,
            x: event.pageX,
            y: event.pageY,
            parent: event.currentTarget as HTMLElement,
        });
    }, [startRippleAt]);
    const onPointerCancel = useCallback<PointerEventHandler<HTMLElement>>((event) => {
        // console.log('pointer cancel');
        endRipple(event.pointerId, true);
    }, [endRipple]);
    const onPointerUp = useCallback<PointerEventHandler<HTMLElement>>((event) => {
        // console.log('pointer end');
        endRipple(event.pointerId, false);
    }, [endRipple]);

    useEffect(() => {
        if (disabled) {
            for (const pointerID of pointerRipple.current.keys()) {
                endRipple(pointerID, true);
            }
        }
    }, [disabled, endRipple]);

    useEffect(() => {
        return () => {
            for (const ripple of ripples.current) {
                clearTimeout(ripple.startTimeoutID);
                clearTimeout(ripple.endTimeoutID);
            }
        };
    }, []);

    return {
        elements: rippleElements,
        onPointerDown,
        onPointerCancel,
        onPointerUp,
    };
}
