import {capitalize as capitalizeFn} from '@vantix/functions/isomorphic/stringTools';
import clsx from 'clsx';
import React, {FunctionComponent, ReactHTML} from 'react';

import classes from './index.module.scss';

export type TypographyProps<T extends keyof ReactHTML, P extends {}> = Parameters<ReactHTML[T]>[0] & P & {
    display?: 'inline' | 'block',
    element?: T | FunctionComponent<P>,
    align?: 'left' | 'center' | 'right' | 'justify',
    variant?: 'huge' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'pLarge' | 'pMedium' | 'pSmall' | 'pXSmall' | 'subtitle' | 'inherit',
    weight?: 'regular' | 'medium' | 'semiBold' | 'bold' | 'extraBold' | 'light',
    color?: 'primary' | 'secondary' | 'disabled' | 'error' | 'warning' | 'success' | 'link' | 'inherit',
    capitalize?: boolean,
    className?: string,
    ellipsisLines?: number,
    style?: React.CSSProperties,
    children?: React.ReactNode,
};

export default function Typography<const T extends keyof ReactHTML, const P extends {}>({
    display,
    element,
    align,
    variant = 'inherit',
    weight,
    color,
    className,
    capitalize,
    style,
    ellipsisLines,
    children,
    ...rest
}: TypographyProps<T, P>): JSX.Element {
    const Element = element || (display === 'inline' ? 'span' : 'div');

    return (
        <Element
            {...rest}
            className={clsx(classes['Typography'], {
                [classes[`Typography--${variant}`]]: variant,
                [classes[`Typography--${weight}`]]: weight,
                [classes['Typography--capitalize']]: capitalize,
                [classes['Typography--ellipsis']]: ellipsisLines > 0,
                [classes[`Typography--color${capitalizeFn(color)}`]]: color,
            }, className)}
            style={{
                ...style,
                textAlign: align,
                WebkitLineClamp: ellipsisLines > 0 ? ellipsisLines : undefined,
            }}
        >{children}</Element>
    );
}
