import {ResolveGenericDBType, T_RTDBPath, createBrowserRTDB} from '@agmedia/firebase-browser-helpers';
import {MainRTDB} from '@vantix/rtdb-rules/default';
import {mangle as MANGLE_TREE, unmangle as UNMANGLE_TREE} from '@vantix/rtdb-rules/default/mangle';
import {getDatabase} from 'firebase/database';
import {useSelector} from 'react-redux';

import {devMode} from 'config';
import ConsoleFactory from 'Console';
import store from 'project-store';

const {RTDB, useRTDBSubscription, useRTDBLoaded, mergeUpdateObjects, addToUpdateObject} = createBrowserRTDB<MainRTDB>({
    console: ConsoleFactory('RTDB'),
    defaultUnwatchTime: 30 * 1000,
    db: () => getDatabase(),
    store: {
        getState: () => store.getState().rtdb,
        setState: store.dispatch.rtdb.set,
        useSelector,
    },
});
RTDB.MANGLE_TREE = MANGLE_TREE;
RTDB.UNMANGLE_TREE = UNMANGLE_TREE;

if (devMode) {
    window.RTDB = RTDB;
}

export {
    addToUpdateObject,
    mergeUpdateObjects,
    useRTDBSubscription,
    useRTDBLoaded,
    RTDB,
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export type ResolveDBType<TPaths extends T_RTDBPath, TWritable extends boolean = false> = ResolveGenericDBType<MainRTDB, TPaths, TWritable>;
