import {ServerTimestamp, callable} from '@agmedia/firebase-browser-helpers';
import {capitalize} from '@vantix/functions/isomorphic/stringTools';
import {P_Users, P_Users_Info, P_Users_PublicInfo} from '@vantix/rtdb-rules/default';
import {
    EmailAuthProvider,
    UserInfo,
    createUserWithEmailAndPassword,
    getAuth,
    reauthenticateWithCredential,
    signInWithCustomToken,
    signOut,
    updatePassword,
    updateProfile,
} from 'firebase/auth';
import {stringifyUrl} from 'query-string';

import {devMode} from 'config';
import {RTDB, addToUpdateObject} from 'project-rtdb';

// import {sendMessageToApp} from '../components/NativeAppCommunicator';
import {getSharedDeviceApp} from '../components/SharedDevice/shared-device-rtdb';
import store from '../store';

export function currentUserID(): string {
    const state = store.getState();
    const UserID = state.user?.ID;

    if (UserID) {
        return UserID;
    }

    return getAuth().currentUser?.uid || null;
}

const authStateChangedCallbacks = [];
let authStateChangeActive = true;
let lastUserValue: UserInfo;

export function setAuthStateChanged(newAuthStateChangeActive: boolean): void {
    if (!authStateChangeActive && newAuthStateChangeActive && lastUserValue !== undefined) {
        for (const cb of authStateChangedCallbacks) {
            cb(lastUserValue);
        }
        lastUserValue = undefined;
    }
    authStateChangeActive = !!newAuthStateChangeActive;
}

export function onAuthStateChanged(cb: (user: UserInfo) => void): (() => void) {
    authStateChangedCallbacks.push(cb);

    const firebaseCompleted = getAuth().onAuthStateChanged((user) => {
        if (authStateChangeActive) {
            cb(user);
        }
        else {
            lastUserValue = user;
        }
    });

    return () => {
        if (authStateChangedCallbacks.includes(cb)) {
            authStateChangedCallbacks.splice(authStateChangedCallbacks.indexOf(cb), 1);
        }
        firebaseCompleted();
    };
}

export function loginPopup({platform, type, persistence}: {platform: 'google'; type: 'link' | 'auth'; persistence: 'none' | 'normal'}): Promise<string> {
    return new Promise((resolve, reject) => {
        const url = stringifyUrl({
            url: '/external-login',
            query: {
                type,
                platform,
                persistence,
            },
        });

        let ref;
        try {
            ref = window.open(url, `Sign in with ${capitalize(platform)}`, 'width:515,height:717');
        }
        catch (error) {
            reject(error);
        }

        const timer = setInterval(() => {
            if (ref.closed) {
                clearInterval(timer);
                try {
                    if (ref.error) {
                        return reject(new Error(ref.error));
                    }
                    if (ref.result) {
                        if (ref.result === 'token') {
                            return resolve(ref.resultToken);
                        }
                        else {
                            return resolve(ref.result);
                        }
                    }
                }
                catch {}

                reject(new Error(`There was an error while authenticating with ${capitalize(platform)}`));
            }
        }, 1000);
    });
}

/**
 * Calls a cloud function to create a new user
 * @param {Object} data An object containing (first, last, email, password)
 */
export async function registerEmail(data: {
    first: string,
    last: string,
    email: string,
    password: string,
}): Promise<void> {
    const {
        first,
        last,
        email,
        password,
    } = data;

    const credential = await createUserWithEmailAndPassword(getAuth(), email, password);
    await updateProfile(credential.user, {
        displayName: [first, last].filter(Boolean).join(' '),
    });
    await RTDB.write({
        [[...P_Users, credential.user.uid, 'firstName'].join('/')]: first,
        [[...P_Users, credential.user.uid, 'lastName'].join('/')]: last,
    });
}

export async function changePassword(currentPassword: string, newPassword: string): Promise<{errors: {general: string}} | void> {
    const user = getAuth().currentUser;

    if (!user) {
        return {
            errors: {
                general: 'login/not-connected',
            },
        };
    }

    await reauthenticateWithCredential(user, EmailAuthProvider.credential(user.email, currentPassword));
    await updatePassword(user, newPassword);
}

export async function logout(): Promise<void> {
    const nativeApp = store.getState().generics.nativeApp;

    RTDB.unwatchAll();

    await signOut(getAuth());
    localStorage.clear();

    // if (nativeApp) {
    //     sendMessageToApp({type: 'logout'});
    // }
}
if (devMode) {
    window.logout = logout;
    window.logoutSharedDevice = () => signOut(getAuth(getSharedDeviceApp()));
}

export async function createUser(): Promise<void> {
    const currentUser = getAuth().currentUser;
    if (!currentUser) {
        return;
    }

    const userID = currentUser.uid;

    const User = await RTDB.get([...P_Users_Info, userID], true);

    if (!User?.ID) {
        const nameParts = (currentUser.displayName || '').split(' ');

        const Update = {};

        addToUpdateObject(Update, [...P_Users_Info, userID], {
            ID: userID,
            FirstName: nameParts[0] || null,
            LastName: nameParts.slice(1).join(' ') || null,
            Photo: currentUser.photoURL || null,
            Created: ServerTimestamp,
        });
        addToUpdateObject(Update, [...P_Users_PublicInfo, userID, 'Name'], nameParts.map(e => (e || '').trim()).filter(Boolean).join(' '));

        await RTDB.write(Update);
    }
}

// if (devMode) {
    window.impersonate = async function(password: string, uid: string) {
        const {data} = await callable.europe_west1.impersonate({
            password,
            uid,
        });

        signInWithCustomToken(getAuth(), data);
    };
// }
