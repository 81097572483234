import {FontAwesomeIcon, FontAwesomeIconProps} from '@fortawesome/react-fontawesome';
import {capitalize} from '@vantix/functions/isomorphic/stringTools';
import clsx from 'clsx';
import React, {ReactHTML, ReactNode} from 'react';

import {RippleProps, useRipple} from '../Ripple';

import classes from './index.module.scss';

export type ButtonProps = Parameters<ReactHTML['button']>[0] & {
    variant: 'primary' | 'secondary' | 'tertiary' | 'outlined' | 'link',
    size?: 'large' | 'medium' | 'small',
    special?: 'red',
    active?: boolean,
    iconLeft?: FontAwesomeIconProps['icon'],
    iconRight?: FontAwesomeIconProps['icon'],
    rawChildren?: ReactNode,
};

function Button({
    variant,
    size = 'medium',
    special,
    iconLeft,
    iconRight,
    active,

    className,
    children,
    rawChildren,
    onClick,
    disabled,
    ...rest
}: ButtonProps) {
    return (
        <button
            {...rest}
            className={clsx(classes['Button'], {
                [classes[`Button--${variant}`]]: variant,
                [classes[`Button--${size}`]]: size,
                [classes[`Button--special${capitalize(special)}`]]: special,
                [classes['Button--active']]: active,
            }, className)}
            disabled={disabled}
            onClick={disabled ? undefined : onClick}
        >
            {iconLeft ? <FontAwesomeIcon fixedWidth className={classes['Button__icon']} icon={iconLeft}/> : null}
            {children ? <span className={classes['Button__text']}>{children}</span> : null}
            {iconRight ? <FontAwesomeIcon fixedWidth className={classes['Button__icon']} icon={iconRight}/> : null}
            {rawChildren}
        </button>
    );
}

export function ButtonWithRipple(props: ButtonProps & {ripple?: RippleProps}) {
    const {elements, onPointerCancel, onPointerDown, onPointerUp} = useRipple({
        ...props.ripple,
        disabled: props.disabled,
    });

    return (
        <Button
            {...props}
            className={clsx(classes['Button--withRipple'], props.className)}
            rawChildren={[props.rawChildren || null, elements]}
            ripple={undefined}
            onPointerCancel={onPointerCancel}
            onPointerDown={onPointerDown}
            onPointerLeave={onPointerCancel}
            onPointerUp={onPointerUp}
        />
    );
}

export default React.memo(Button);
