import {createModel} from '@rematch/core';
import {MainRTDB} from '@vantix/rtdb-rules/default';

import {RootModel} from '..';

const initialState: {
    data: MainRTDB['false'],
    loaded: any,
} = {} as any;

export const rtdb = createModel<RootModel>()({
    state: initialState,
    effects: {},
    reducers: {
        set(state, update) {
            return update;
        },
    },
});
