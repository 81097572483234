import {FontAwesomeIcon, FontAwesomeIconProps} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {AnimatePresence, motion} from 'framer-motion';
import {ForwardedRef, ReactHTML, ReactNode, forwardRef, memo} from 'react';

import Typography, {TypographyProps} from '../Typography';

import classes from './index.module.scss';

export type InputProps = Parameters<ReactHTML['input']>[0] & {
    label?: ReactNode,
    helper?: ReactNode,
    hideHelperWhenError?: boolean,
    error?: string,
    errorProps?: TypographyProps<'div', {}>,
    icon?: FontAwesomeIconProps['icon'],
    virtualkeyboardpolicy?: 'manual' | 'auto',
    labelSize?: TypographyProps<'div', {}>['variant'],
    rightAdornment?: ReactNode,
    inputProps?: Parameters<ReactHTML['input']>[0],
};

function Input({
    label,
    helper,
    id,
    className,
    error,
    errorProps,
    icon,
    style,
    labelSize = 'pSmall',
    hideHelperWhenError = true,
    rightAdornment,
    inputProps,
    ...rest
}: InputProps, ref: ForwardedRef<HTMLInputElement>) {
    return (
        <div
            className={clsx(className, classes['Input'], {
                [classes['Input--icon']]: icon,
                [classes['Input--error']]: error,
                [classes['Input--label']]: label,
            })}
            style={style}
        >
            {label ? (
                <Typography
                    color="secondary"
                    element="label"
                    htmlFor={id}
                    variant={labelSize}
                    weight="medium"
                >{label}</Typography>
            ) : null}
            <div className={clsx(classes['Input__inputLine'])}>
                {icon ? <FontAwesomeIcon fixedWidth className={classes['Input__icon']} icon={icon} /> : null}
                <input
                    {...rest}
                    {...inputProps}
                    ref={ref}
                    className={clsx(classes['Input__input'], inputProps?.className)}
                    id={id}
                />
                {rightAdornment ? (
                    <Typography
                        className={classes['Input__inputLine__adornment']}
                        color="secondary"
                        variant="pMedium"
                    >{rightAdornment}</Typography>
                ) : null}
            </div>
            <AnimatePresence initial={false}>
                {error ? (
                    <Typography
                        key="error"
                        animate={{height: 'auto'}}
                        className={classes['Input__error']}
                        color="error"
                        element={motion.div}
                        exit={{height: 0}}
                        initial={{height: 0}}
                        style={{overflow: 'hidden'}}
                        transition={{duration: 0.1}}
                        variant="pSmall"
                        {...errorProps}
                    >{error}</Typography>
                ) : null}
                {hideHelperWhenError && error ? null : helper ? (
                    <Typography
                        key="helper"
                        animate={{height: 'auto'}}
                        className={classes['Input__helper']}
                        color="secondary"
                        element={motion.div}
                        exit={{height: 0}}
                        initial={{height: 0}}
                        style={{overflow: 'hidden'}}
                        transition={{duration: 0.1}}
                        variant="pSmall"
                    >{helper}</Typography>
                ) : null}
            </AnimatePresence>
        </div>
    );
}

export default memo(forwardRef<HTMLInputElement, InputProps>(Input));
