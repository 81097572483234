import {compare, hash} from 'bcryptjs';

const saltRounds = 11;

export function hashPassword(password: string) {
    return hash(password, saltRounds);
}

export function comparePasswordWithHash(password: string, hash: string) {
    return compare(password, hash);
}
