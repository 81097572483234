import telemetry from 'telemetry-sh';

import {devMode, telemetryAPIKey} from 'config';

// eslint-disable-next-line require-await
export async function initTelemetry(): Promise<void> {
    telemetry.init(telemetryAPIKey);

    if (devMode) {
        window.telemetry = telemetry;
    }
}
