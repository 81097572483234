import clsx from 'clsx';

import {ButtonProps, ButtonWithRipple} from '@/components/Button';

import classes from './index.module.scss';

export interface TouchButtonProps extends Omit<ButtonProps, 'variant' | 'size' | 'rawChildren' | 'color'> {
    color?: 'blue' | 'green',
}

export default function TouchButton(props: TouchButtonProps) {
    return (
        <ButtonWithRipple
            {...props}
            className={clsx(props.className, classes['TouchButton'], {
                [classes[`TouchButton--${props.color || 'blue'}`]]: props.color || 'blue',
            })}
            rawChildren={
                <span key="content" className={classes['TouchButton__inner']}>
                    {props.children}
                </span>
            }
            ripple={{
                color: 'white',
            }}
            size="large"
        >{null}</ButtonWithRipple>
    );
}
