import {parse as uuidParse, v4 as uuidv4, v5 as uuidv5} from 'uuid';

import {decodeRTDBKey, encodeRTDBKey} from './firebase';

export const ERPClientUUIDNamespace = encodeRTDBKey(uuidParse('e572b349-4a72-445b-825f-1fb4c4a37571'));

const rtdbUUIDBuffer = new Uint8Array(16);
export function rtdbUUID(input: string, namespace: string): string {
    return encodeRTDBKey(uuidv5(input, decodeRTDBKey(namespace), rtdbUUIDBuffer));
}

export function randomUUID(): string {
    return encodeRTDBKey(uuidv4(null, rtdbUUIDBuffer));
}
