import {createModel} from '@rematch/core';
import {T_DB_ShopProductID} from '@vantix/rtdb-rules/default';
import deepmerge from 'deepmerge';

import ConsoleFactory from 'Console';

import {RootModel} from '..';

const Console = ConsoleFactory('Rematch - Generics');

const persistConfig = {
    key: 'efactura_generics',
    whitelist: ['anafAuthAttemptID', 'anafAuthAttemptUserID', 'zohoOrganizationID'],
};

interface GenericsState {
    loadingUser: boolean,
    pageLoading: number,
    authUserID?: string,
    erpUserID?: string,
    renderDocumentsCF?: boolean,
    nativeApp?: boolean,
    nativeNotificationsEnabled?: boolean,
    nativeNotificationsRequested?: boolean,
    requestedNotificationPermission?: boolean,
    preventUserLoad?: boolean,
    anafAuthAttemptID?: string,
    anafAuthAttemptUserID?: string,
    integrationTargets?: string[],

    isOffline: boolean,

    SharedDeviceID?: string,
    SharedDeviceIDLoading: boolean,

    sharedDeviceOperatorRefresh: number,

    labelsToPrint?: Record<T_DB_ShopProductID, {Amount: number}>,
}

let storedConfig: GenericsState = {
    loadingUser: true,
    SharedDeviceIDLoading: true,
    isOffline: true,
    pageLoading: 0,
    sharedDeviceOperatorRefresh: 0,
};
try {
    storedConfig = JSON.parse(localStorage.getItem(persistConfig.key));
}
catch {}
const initialState: GenericsState = {
    ...storedConfig,
    loadingUser: true,
    SharedDeviceIDLoading: true,
    pageLoading: 0,
    isOffline: true,
    sharedDeviceOperatorRefresh: 0,
};

export const generics = createModel<RootModel>()({
    state: initialState,
    effects: {},
    reducers: {
        set(state, update: Partial<GenericsState>) {
            if (typeof update !== 'object' || !update) {
                Console.error('generics.set must receive an update object');
                return state;
            }

            const newState = {...state};

            for (const key of Object.keys(update)) {
                newState[key] = update[key];
            }

            const filteredState = {};
            for (const e of Object.keys(newState)) {
                if (persistConfig.whitelist.includes(e)) {
                    filteredState[e] = newState[e];
                }
            }
            try {
                localStorage.setItem(persistConfig.key, JSON.stringify(filteredState));
            }
            catch {}

            return newState;
        },
        update: (state, update: Partial<GenericsState>) => {
            if (typeof update !== 'object' || !update) {
                Console.error('generics.update must receive an update object');
                return state;
            }

            const newState = deepmerge(state, update);

            const filteredState = {};
            for (const e of Object.keys(newState)) {
                if (persistConfig.whitelist.includes(e)) {
                    filteredState[e] = newState[e];
                }
            }
            try {
                localStorage.setItem(persistConfig.key, JSON.stringify(filteredState));
            }
            catch {}

            return newState;
        },
    },
});
