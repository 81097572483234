import {getFunctions} from '@agmedia/firebase-browser-helpers';
import {initializeAnalytics, setAnalyticsCollectionEnabled} from 'firebase/analytics';
import {initializeApp} from 'firebase/app';
import {connectAuthEmulator, getAuth} from 'firebase/auth';
import {connectDatabaseEmulator, getDatabase} from 'firebase/database';
import {connectFunctionsEmulator} from 'firebase/functions';

import firebaseConfig from 'firebase-config';

export default async function initFirebase(): Promise<void> {
    const app = initializeApp(firebaseConfig);
    const analytics = initializeAnalytics(app);
    setAnalyticsCollectionEnabled(analytics, false);

    if (process.env.NODE_ENV === 'emulators') {
        connectFunctionsEmulator(getFunctions(), 'localhost', 5503);
        connectDatabaseEmulator(getDatabase(), 'localhost', 5504);
        connectAuthEmulator(getAuth(), 'http://localhost:5502/');
    }
}
