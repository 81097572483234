import {createModel} from '@rematch/core';

import {RootModel} from '..';

interface ShopState {

}

export const shop = createModel<RootModel>()({
    state: { // eslint-disable-line @typescript-eslint/consistent-type-assertions

    } as ShopState,
    effects: {},
    reducers: {
        update: (state, payload: Partial<ShopState>) => {},
    },
});
