import ulog from 'ulog';

if (process.env.NODE_ENV === 'production') {
    ulog.level = ulog.ERROR;
}
else {
    ulog.level = ulog.TRACE;
}

const modules = [];

export const LOG_LEVELS = {
    LOG_NONE: 0,
    LOG_ERROR: 1,
    LOG_WARN: 2,
    LOG_INFO: 3,
    LOG_LOG: 4,
    LOG_VERBOSE: 4,
    LOG_DEBUG: 5,
    LOG_TRACE: 6,
};

let blacklist;
if (process.env.NODE_ENV !== 'production') {
    try {
        blacklist = require('./Console.blacklist');
        blacklist = blacklist?.default || blacklist;
    }
    catch {}
}

const nop = () => {};

const levels = {
    error: 1,
    warn: 2,
    info: 3,
    log: 4,
    verbose: 4,
    debug: 5,
    trace: 6,
    silly: 6,
    dir: 0,
    table: 0,
    time: 0,
    timeEnd: 0,
    assert: 0,
};

const nopConsole = Object.keys(levels).reduce((acc, name) => {
    acc[name] = nop;
    return acc;
}, {});

export default function createLogger(moduleName = 'No module name') {
    if (modules.includes(moduleName)) {
        // doesn't work like intended with hot reload
        if (!module?.hot?.active) {
            ulog.warn('Duplicate module names:', moduleName);
        }
        return ulog(moduleName);
    }
    modules.push(moduleName);

    const blacklistSetting = blacklist?.[moduleName];
    if (blacklistSetting <= 0) {
        return nopConsole;
    }

    const newLogger = ulog(moduleName);

    for (const name of Object.keys(levels)) {
        if (!blacklistSetting || levels[name] <= blacklistSetting) {
            newLogger[name] = newLogger[name].bind(newLogger, '[' + moduleName + ']');
        }
        else {
            newLogger[name] = nop;
        }
    }

    return newLogger;
}
