import {Blocker, History} from 'history';
import React, {useContext, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Location, UNSAFE_NavigationContext as NavigationContext, useLocation, useParams} from 'react-router';
import {NavigateFunction, useNavigate} from 'react-router-dom';

import {useSharedDeviceRTDBSubscription} from '@/components/SharedDevice/shared-device-rtdb';
import {useRTDBSubscription} from 'project-rtdb';
import {RootState} from 'project-store';

import {getCurrentSignedInOperatorID} from './SharedDevice';

export const ROUTER_ROOTS = {
    CheckoutStation: '/checkout-station',
    PDA: '/pda',
    Organization: '/',
} as const;

export function WatchPath({path, options}: {path: string[]; options?: Parameters<typeof useRTDBSubscription>[1]}): React.ReactElement {
    useRTDBSubscription(path, options);

    return null;
}

export function WatchPathSharedDevice({path, options}: {path: string[]; options?: Parameters<typeof useSharedDeviceRTDBSubscription>[1]}): React.ReactElement {
    useSharedDeviceRTDBSubscription(path, options);

    return null;
}

export function Redirect({to, replace}: {to: string; replace?: boolean}): React.ReactElement {
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        let parameterisedTo = to;

        for (const [key, value] of Object.entries(params)) {
            parameterisedTo = parameterisedTo.replaceAll(`:${key}`, value);
        }

        navigate(parameterisedTo, {
            replace,
        });
    });

    return null;
}

interface EnsureUserProps {
    shouldRenderChildren(conditionResult: {
        loadingUser: boolean,
        user: RootState['user'],
    }): boolean,
    callback(conditionResult: {
        loadingUser: boolean,
        user: RootState['user'],
    }, location: Location, navigate: NavigateFunction): void,
    fallback?: React.ReactNode,
    children?: React.ReactNode,
}

export function EnsureUser(props: EnsureUserProps): JSX.Element {
    const {
        shouldRenderChildren,
        callback,
        fallback,
        children,
    } = props;

    let navigate: NavigateFunction;
    let location: Location;
    try {
        navigate = useNavigate();
        location = useLocation();
    }
    catch {}

    const loadingUser = useSelector(state => state.generics.loadingUser);
    const user = useSelector(state => state.user);
    const info = {loadingUser, user};

    useEffect(() => {
        callback(info, location, navigate);
    });

    if (shouldRenderChildren(info)) {
        return <>{children}</>;
    }

    return <>{fallback}</>;
}

interface EnsureSharedDeviceUserProps {
    shouldRenderChildren(conditionResult: {
        SharedDeviceIDLoading: boolean,
        SharedDeviceID: string,
    }): boolean,
    callback(conditionResult: {
        SharedDeviceIDLoading: boolean,
        SharedDeviceID: string,
    }, location: Location, navigate: NavigateFunction): void,
    fallback?: React.ReactNode,
    children?: React.ReactNode,
}

export function EnsureSharedDevice(props: EnsureSharedDeviceUserProps): JSX.Element {
    const {
        shouldRenderChildren,
        callback,
        fallback,
        children,
    } = props;

    let navigate: NavigateFunction;
    let location: Location;
    try {
        navigate = useNavigate();
        location = useLocation();
    }
    catch {}

    const SharedDeviceID = useSelector(state => state.generics.SharedDeviceID);
    const SharedDeviceIDLoading = useSelector(state => state.generics.SharedDeviceIDLoading);
    const info = {SharedDeviceID, SharedDeviceIDLoading};

    useEffect(() => {
        callback(info, location, navigate);
    });

    if (shouldRenderChildren(info)) {
        return <>{children}</>;
    }

    return <>{fallback}</>;
}

interface EnsureOperatorProps {
    shouldRenderChildren(conditionResult: {
        OperatorID: string,
    }): boolean,
    callback(conditionResult: {
        OperatorID: string,
    }, location: Location, navigate: NavigateFunction): void,
    fallback?: React.ReactNode,
    children?: React.ReactNode,
}

export function EnsureOperator(props: EnsureOperatorProps): JSX.Element {
    const {
        shouldRenderChildren,
        callback,
        fallback,
        children,
    } = props;

    let navigate: NavigateFunction;
    let location: Location;
    try {
        navigate = useNavigate();
        location = useLocation();
    }
    catch {}

    useSelector(state => state.generics.sharedDeviceOperatorRefresh);
    const info = {OperatorID: getCurrentSignedInOperatorID()};

    useEffect(() => {
        callback(info, location, navigate);
    });

    if (shouldRenderChildren(info)) {
        return <>{children}</>;
    }

    return <>{fallback}</>;
}


export function useBlocker(blocker: Blocker, when = true): void {
    const {navigator} = useContext(NavigationContext) as unknown as ({navigator: History});

    useEffect(() => {
        if (!when) {
            return;
        }

        const unblock = navigator.block((tx) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    unblock();
                    tx.retry();
                },
            };

            blocker(autoUnblockingTx);
        });

        return unblock;
    }, [navigator, blocker, when]);
}
