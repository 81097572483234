import clsx from 'clsx';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import store from 'project-store';

import Spinner from '../Spinner';

import classes from './index.module.scss';

export function PageLoaderRenderer() {
    const active = useSelector(state => state.generics.pageLoading);

    return (
        <div
            className={clsx(classes['PageLoader'], {
                [classes['PageLoader--active']]: active > 0,
            })}
        >
            <Spinner size="large" variant="page" />
        </div>
    );
}

export function PageLoader() {
    useEffect(() => {
        store.dispatch.generics.update({
            pageLoading: store.getState().generics.pageLoading + 1,
        });

        return () => {
            setTimeout(() => {
                store.dispatch.generics.update({
                    pageLoading: store.getState().generics.pageLoading - 1,
                });
            });
        };
    }, []);

    return null;
}
