import {callable} from '@agmedia/firebase-browser-helpers';
import {decodeRTDBKey, decodeRTDBNumber, encodeRTDBKey, encodeRTDBNumber} from '@vantix/functions/isomorphic/firebase';
import {randomUUID, rtdbUUID} from '@vantix/functions/isomorphic/uuid';
import {P_Users_Info} from '@vantix/rtdb-rules/default';
import BigNumber from 'bignumber.js';
import {getAuth} from 'firebase/auth';
import {Fragment, ReactNode, StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import {ErrorBoundary} from 'react-error-boundary';
import {Provider} from 'react-redux';
import {useNavigate} from 'react-router';
import {BrowserRouter} from 'react-router-dom';
import telemetry from 'telemetry-sh';

import App from '@/App';
import {db} from '@/dexie';
import initFirebase from '@/firebase.init';
import {initI18N} from '@/i18n';
import {initTelemetry} from '@/telemetry';
import {currentUserID} from '@/utils/Auth';
import {getScrollbarWidth} from '@/utils/BrowserTools';
import {devMode} from 'config';
import {RTDB} from 'project-rtdb';
import store, {idbCache, idbCacheContext} from 'project-store';

import applySideEffects from './sideEffects';

let oldVh;
export function updateVh() {
    const vh = Math.round(window.innerHeight) / 100;
    if (oldVh === vh) {
        return;
    }
    oldVh = vh;

    document.body.style.setProperty('--vh', `${vh}px`);
}

if ('serviceWorker' in window.navigator) {
    (async () => {
        const {Workbox} = await import('workbox-window');
        const wb = new Workbox('/service-worker.js');

        window.wb = wb;
        try {
            window.serviceWorkerRegistration = await wb.register();
        }
        catch (error) {
            console.error('Could not install SW', error);
        }
    })();
}

export async function createApp(content: ReactNode): Promise<void> {
    if (devMode) {
        window.callable = callable;
        window.db = db;
        window.BigNumber = BigNumber;

        window.decodeRTDBKey = decodeRTDBKey;
        window.encodeRTDBKey = encodeRTDBKey;
        window.decodeRTDBNumber = decodeRTDBNumber;
        window.encodeRTDBNumber = encodeRTDBNumber;
        window.rtdbUUID = rtdbUUID;
        window.randomUUID = randomUUID;

        window.store = store;
        window.idbCache = idbCache;
    }

    await Promise.all([
        applySideEffects(),
        initFirebase(),
        initI18N(),
        initTelemetry(),
    ]);

    window.addEventListener('resize', updateVh);
    updateVh();


    window.navigate = undefined;
    function SaveNavigate() {
        window.navigate = useNavigate();

        return null;
    }

    document.body.style.setProperty('--scrollbarWidth', `${Math.ceil(getScrollbarWidth())}px`);

    const pageContent = (
        <StrictMode>
            <Provider context={idbCacheContext} store={idbCache}>
                <Provider store={store}>
                    <BrowserRouter>
                        <SaveNavigate />
                        <App>
                            {content}
                        </App>
                    </BrowserRouter>
                </Provider>
            </Provider>
        </StrictMode>
    );

    const root = createRoot(document.querySelector('#root'));
    if (devMode) {
        root.render(pageContent);
    }
    else {
        root.render(
            <ErrorBoundary
                // todo: add a graphic page for the fallback
                fallbackRender={() => <Fragment />}
                onError={(error) => {
                    (async () => {
                        try {
                            await telemetry.log('react_render_error', {
                                userID: getAuth().currentUser?.uid,
                                user: RTDB.store.selectPath([...P_Users_Info, currentUserID()]),
                                windowLocation: window.location.href || '',
                                errorMessage: error?.message,
                                errorCode: error?.code,
                                stack: error?.stack,
                            });
                        }
                        catch {}
                    })();
                }}
            >{pageContent}</ErrorBoundary>,
        );
    }
}
