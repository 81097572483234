import {createModel} from '@rematch/core';
import {P_RBAC_Computed_ByUser} from '@vantix/rtdb-rules/default';

import ConsoleFactory from 'Console';
import {ResolveDBType} from 'project-rtdb';

import {RootModel} from '..';

const Console = ConsoleFactory('Rematch - Feature Flags');

type RBACState = Partial<ResolveDBType<[...P_RBAC_Computed_ByUser, string]>>;

const initialState: RBACState = {};

export const rbac = createModel<RootModel>()({
    state: initialState,
    effects: {},
    reducers: {
        update(state, update: Partial<RBACState>) {
            if (typeof update !== 'object' || !update) {
                Console.error('rbac.update must receive an update object');
                return state;
            }

            const newState = {...state};

            for (const key of Object.keys(update)) {
                newState[key] = update[key];
            }

            return newState;
        },
        reset() {
            return {};
        },
    },
});
