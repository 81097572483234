import {Models} from '@rematch/core';

import {checkout} from './rematch/checkout';
import {featureFlags} from './rematch/featureFlags';
import {generics} from './rematch/generics';
import {rbac} from './rematch/rbac';
import {rtdb} from './rematch/rtdb';
import {shop} from './rematch/shop';
import {sync} from './rematch/sync';
import {user} from './rematch/user';

export interface RootModel extends Models<RootModel> {
    rtdb: typeof rtdb,
    sharedDeviceRTDB: typeof rtdb,
    generics: typeof generics,
    user: typeof user,
    // featureFlags: typeof featureFlags,
    rbac: typeof rbac,
    shop: typeof shop,
    checkout: typeof checkout,
    sync: typeof sync,
}

export const models: RootModel = {
    rtdb,
    sharedDeviceRTDB: rtdb,
    generics,
    user,
    featureFlags,
    rbac,
    shop,
    checkout,
    sync,
};
