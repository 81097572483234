export const T_SECOND = 1000;
export const T_MINUTE = 60 * T_SECOND;
export const T_HOUR = 3600 * T_SECOND;
export const T_DAY = T_HOUR * 24;
export const T_WEEK = T_DAY * 7;
export const T_MONTH = T_DAY * 30;
export const T_YEAR = T_DAY * 365;

type TDirection = 'asc' | 'desc' | 'lexicoAsc' | 'lexicoDesc';
export function sortBy<TA>(...accessors: ((a: TA) => ([number | string, TDirection] | number | string))[]) {
    return (a: TA, b: TA): number => {
        for (const accessor of accessors) {
            let aField = accessor(a);
            let bField = accessor(b);
            let direction: TDirection = 'asc';

            if (Array.isArray(aField)) {
                direction = aField[1];
                aField = aField[0];
            }
            if (Array.isArray(bField)) {
                direction = bField[1];
                bField = bField[0];
            }

            let result;
            if (typeof aField === 'number' && typeof bField === 'number') {
                result = aField - bField;
            }
            else if (typeof aField === 'string' || typeof bField === 'string') {
                if (direction === 'lexicoAsc' || direction === 'lexicoDesc') {
                    result = aField === bField ? 0 : aField > bField ? 1 : -1;
                }
                else {
                    result = ('' + aField).localeCompare('' + bField);
                }
            }
            else {
                result = 0;
            }

            if (result !== 0) {
                return (direction === 'asc' || direction === 'lexicoAsc' ? 1 : -1) * result;
            }
        }
    };
}
