import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import {initReactI18next} from 'react-i18next';

export async function initI18N(): Promise<void> {
    const instance = (
        i18n
            .use(initReactI18next) // passes i18n down to react-i18next
            .use(intervalPlural)
            .use(Backend)
    );

    if (process.env.NODE_ENV !== 'production') {
        const {HMRPlugin} = await import('i18next-hmr/plugin');
        instance.use(
            new HMRPlugin({
                webpack: {
                    client: true,
                },
            }),
        );
    }

    instance.init({
        load: 'languageOnly',
        lng: 'ro',
        fallbackLng: false,
        partialBundledLanguages: true,
        resources: {},
        fallbackNS: 'translation',
        supportedLngs: ['ro', 'en'],
        react: {
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b', 'p', 'span'],
        },

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            skipOnVariables: false,
            escapeValue: false, // react already safes from xss
        },
    });

    window.i18n = instance;
}
