import {comparePasswordWithHash} from '@vantix/functions/isomorphic/bcrypt';
import {T_DB_ComputedSharedDeviceUser} from '@vantix/rtdb-rules/default';

import {db} from '@/dexie';
import {devMode} from 'config';
import store from 'project-store';

import {getCurrentSharedDevice} from './indexeddb';

let currentSignedInOperator: string = devMode ? 'EFuLXkStJGNvZgxvIPE21' : undefined;

export function setCurrentSignedInOperator(userID: string): void {
    currentSignedInOperator = userID;

    store.dispatch.generics.update({
        sharedDeviceOperatorRefresh: store.getState().generics.sharedDeviceOperatorRefresh + 1,
    });
}
export function getCurrentSignedInOperatorID(): string {
    return currentSignedInOperator;
}
export async function getCurrentSignedInOperator(): Promise<T_DB_ComputedSharedDeviceUser> {
    const sharedDeviceID = store.getState().generics.SharedDeviceID;

    return (await db.sharedDevices.get(sharedDeviceID))?.Users?.[getCurrentSignedInOperatorID()];
}

// todo: since SharedDevices might be accessible by the general public, it may be wise to prevent brute-force attacks
export async function signInOperator({userID, pin}: {userID: string; pin: string}): Promise<void> {
    if (await comparePasswordWithHash(pin, (await getCurrentSharedDevice())?.Users?.[userID]?.Pin) === false) {
        throw new Error('Wrong password');
    }

    setCurrentSignedInOperator(userID);
}
