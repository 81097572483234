import {createBrowserRTDB} from '@agmedia/firebase-browser-helpers';
import {MainRTDB} from '@vantix/rtdb-rules/default';
import {mangle as MANGLE_TREE, unmangle as UNMANGLE_TREE} from '@vantix/rtdb-rules/default/mangle';
import {FirebaseApp, getApp, initializeApp} from 'firebase/app';
import {getDatabase} from 'firebase/database';
import {useSelector} from 'react-redux';

import {devMode} from 'config';
import ConsoleFactory from 'Console';
import firebaseConfig from 'firebase-config';
import store from 'project-store';

export const FIREBASE_SHARED_DEVICE_APP = 'SharedDevice';

export function getSharedDeviceApp(): FirebaseApp {
    try {
        getApp(FIREBASE_SHARED_DEVICE_APP);
    }
    catch {
        initializeApp(firebaseConfig, FIREBASE_SHARED_DEVICE_APP);
    }

    return getApp(FIREBASE_SHARED_DEVICE_APP);
}

const {
    RTDB: SharedDeviceRTDB,
    useRTDBSubscription: useSharedDeviceRTDBSubscription,
    useRTDBLoaded: useSharedDeviceRTDBLoaded,
} = createBrowserRTDB<MainRTDB>({
    console: ConsoleFactory('Checkout Station RTDB'),
    defaultUnwatchTime: 30 * 1000,
    db: () => {
        return getDatabase(getSharedDeviceApp());
    },
    store: {
        getState: () => store.getState().sharedDeviceRTDB,
        setState: store.dispatch.sharedDeviceRTDB.set,
        useSelector,
    },
});
SharedDeviceRTDB.MANGLE_TREE = MANGLE_TREE;
SharedDeviceRTDB.UNMANGLE_TREE = UNMANGLE_TREE;

if (devMode) {
    window.SharedDeviceRTDB = SharedDeviceRTDB;
}

export {
    useSharedDeviceRTDBLoaded,
    useSharedDeviceRTDBSubscription,
    SharedDeviceRTDB,
};
