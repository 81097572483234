import {getEscapedProductCode} from '@vantix/functions/isomorphic/compute/products';
import {ComputedProductData} from '@vantix/functions/isomorphic/data';
import {T_DB_CheckoutStationSale, T_DB_ShopProduct} from '@vantix/rtdb-rules/default';
import {dequal} from 'dequal';
import {KeyPathValue, KeyPaths, Subscription, UpdateSpec, liveQuery} from 'dexie';
import {InteropableObservable} from 'dexie-react-hooks';
import prettyMilliseconds from 'pretty-ms';
import {useDebugValue, useEffect, useMemo, useReducer, useRef} from 'react';
import {useSelector} from 'react-redux';

import store, {idbCache, useIDBCache} from 'project-store';

import {SharedDeviceData, db} from '../dexie';

export function useCurrentOrder<T = T_DB_CheckoutStationSale>(selector?: (order?: T_DB_CheckoutStationSale) => T, deps?: unknown[]): T {
    const orderID = useSelector(state => state.checkout.orderID);

    return useIDBSelector(
        () => !orderID ? null : db.orders.get(orderID),
        [orderID],
        selector,
        deps,
    );
}
export function getCurrentOrder(): Promise<T_DB_CheckoutStationSale> {
    return db.orders.get(store.getState().checkout.orderID);
}
export function updateCurrentOrder<Path extends KeyPaths<T_DB_CheckoutStationSale>>(key: Path, value: KeyPathValue<T_DB_CheckoutStationSale, Path>): Promise<number> {
    return db.orders.update(store.getState().checkout.orderID, {
        [key]: value,
        'meta.sync': '1',
    } as UpdateSpec<T_DB_CheckoutStationSale>);
}


export function useCurrentSharedDevice<T = SharedDeviceData>(selector?: (sharedDevice?: SharedDeviceData) => T, deps?: unknown[]): T {
    const sharedDeviceID = useSelector(state => state.generics.SharedDeviceID);

    return useIDBSelector(
        () => !sharedDeviceID ? null : db.sharedDevices.get(sharedDeviceID),
        [sharedDeviceID],
        selector,
        deps,
    );
}
export function getCurrentSharedDevice(): Promise<SharedDeviceData> {
    return db.sharedDevices.get(store.getState().generics.SharedDeviceID);
}

export function useIDBSelector<TI, TO>(querier: () => TI | Promise<TI>, keyDeps: string[], selector?: (input?: TI) => TO, deps?: unknown[]): TO {
    if (typeof selector !== 'function') {
        selector = (val) => val as any as TO; // eslint-disable-line @typescript-eslint/no-explicit-any
    }

    // Create a ref that keeps the state we need
    const monitor = useRef({
        hasResult: false,
        result: selector(undefined),
        error: null,
    });
    // We control when component should rerender. Make triggerUpdate
    // as examplified on React's docs at:
    // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
    const [, triggerUpdate] = useReducer((x) => x + 1, 0);

    // Memoize the observable based on deps
    const observable = useMemo(() => {
        // Make it remember previous subscription's default value when
        // resubscribing.
        const observable: InteropableObservable<TI> = liveQuery(querier);

        if (!monitor.current.hasResult && (typeof observable.hasValue !== 'function' || observable.hasValue())) {
            if (typeof observable.getValue === 'function') {
                monitor.current.result = selector(observable.getValue());
                monitor.current.hasResult = true;
            }
            else {
                // Find out if the observable has a current value: try get it by subscribing and
                // unsubscribing synchronously
                const subscription = observable.subscribe((val) => {
                    monitor.current.result = selector(val);
                    monitor.current.hasResult = true;
                });
                // Unsubscribe directly. We only needed any synchronous value if it was possible.
                if (typeof subscription === 'function') {
                    subscription();
                }
                else {
                    subscription.unsubscribe();
                }
            }
        }
        return observable;
    }, keyDeps);

    // Integrate with react devtools:
    useDebugValue(monitor.current.result);

    // Subscribe to the observable
    useEffect(() => {
        if (keyDeps.some(e => !e)) {
            return;
        }

        const subscription = observable.subscribe(
            (val) => {
                const {current} = monitor;
                const selected = selector(val);
                if (current.error !== null || !dequal(current.result, selected)) {
                    current.error = null;
                    current.result = selected;
                    current.hasResult = true;
                    triggerUpdate();
                }
            },
            (err) => {
                const {current} = monitor;
                if (current.error !== err) {
                    current.error = err;
                    triggerUpdate();
                }
            },
        );
        return typeof subscription === 'function'
            ? subscription // Support observables that return unsubscribe directly
            : subscription.unsubscribe.bind(subscription);
    }, [...keyDeps, ...(deps || [])]);

    useEffect(() => {
        if (keyDeps.some(e => !e)) {
            return;
        }

        return () => {
            if (monitor.current.hasResult) {
                monitor.current.error = null;
                monitor.current.result = selector(undefined);
                monitor.current.hasResult = false;
                triggerUpdate();
            }
        };
    }, keyDeps);

    // Throw if observable has emitted error so that
    // an ErrorBoundrary can catch it
    if (monitor.current.error) {
        throw monitor.current.error;
    }

    // Return the current result
    return monitor.current.result;
}

export async function updateLocalProducts(ShopID: string): Promise<void> {
    if (!ShopID) {
        return;
    }

    const products = await (await fetch(`https://storage.vantix-erp.com/product-data/${ShopID}.json`)).json() as ComputedProductData;

    const start = Date.now();

    const [currentProductsArray, currentProductCodesArray] = await Promise.all([
        db.productData.toArray(),
        db.productCodes.toArray(),
    ]);
    const currentProducts = Object.fromEntries(currentProductsArray.filter(e => e?.ID).map(product => [product.ID, product]));
    const currentProductCodes = Object.fromEntries(currentProductCodesArray.filter(e => e?.ProductID).map(code => [code.Code, code.ProductID]));

    const dexieProductUpdates: T_DB_ShopProduct[] = [];
    const dexieProductCodeUpdates: {Code: string; ProductID: string}[] = [];

    for (const Product of Object.values(products.ByID || {})) {
        if (!Product?.ID) {
            continue;
        }

        if (!dequal(Product, currentProducts[Product.ID])) {
            dexieProductUpdates.push(Product);
        }
    }
    await Promise.all(
        currentProductsArray
            .filter(localProduct => localProduct?.ID && !products.ByID?.[localProduct.ID])
            .map((localProduct) => (
                Promise.all([
                    db.productData.delete(localProduct.ID),
                    localProduct.Code?.Value ? db.productCodes.delete(localProduct.Code.Value) : undefined,
                ])
            )),
    );
    for (const [ProductCode, ProductID] of Object.entries(products.ByCode || {})) {
        if (!ProductID || !ProductCode) {
            continue;
        }

        if (currentProductCodes[ProductCode] !== ProductID) {
            dexieProductCodeUpdates.push({
                Code: ProductCode,
                ProductID,
            });
        }
    }
    await Promise.all([
        dexieProductUpdates.length > 0 ? db.productData.bulkPut(dexieProductUpdates) : undefined,
        dexieProductCodeUpdates.length > 0 ? db.productCodes.bulkPut(dexieProductCodeUpdates) : undefined,
    ]);

    console.log('PDB full update took', prettyMilliseconds(Date.now() - start));
}

// todo: start unsubscribing after 1k products in store?
const productDataSubscriptions: {
    Products: Record<string, Subscription>,
    ProductCodes: Record<string, Subscription>,
} = {
    Products: {},
    ProductCodes: {},
};
export interface useProductDataProps {
    ProductID?: string,
    ProductCode?: string,
}
export function useProductData({ProductID, ProductCode}: useProductDataProps): T_DB_ShopProduct | null {
    const escapedProductCode = getEscapedProductCode(ProductCode);

    const productIDBasedOnCode = useIDBCache(state => state.cache.productCodes[escapedProductCode]);

    useEffect(() => {
        if (ProductID || !escapedProductCode) {
            return;
        }

        productDataSubscriptions.ProductCodes[escapedProductCode] ||= (
            liveQuery(() => db.productCodes.get(escapedProductCode))
        ).subscribe((val) => {
            idbCache.dispatch.cache.update({
                productCodes: {
                    [escapedProductCode]: val?.ProductID || null,
                },
            });
        });
    }, [ProductID, escapedProductCode]);
    useEffect(() => {
        let cancelled = false;

        (async () => {
            const productID = (
                ProductID
                ?? productIDBasedOnCode
                ?? (escapedProductCode ? (await db.productCodes.get(escapedProductCode))?.ProductID : null)
            );

            if (!productID || cancelled) {
                return;
            }

            productDataSubscriptions.Products[productID] ||= (
                liveQuery(() => db.productData.get(productID))
            ).subscribe((val) => {
                idbCache.dispatch.cache.update({
                    productData: {
                        [productID]: val || null,
                    },
                });
            });
        })();

        return () => {
            cancelled = true;
        };
    }, [ProductID, escapedProductCode, productIDBasedOnCode]);

    return useIDBCache(state => (
        ProductID
            ? state.cache.productData[ProductID]
            : escapedProductCode && state.cache.productCodes[escapedProductCode] !== null
                ? state.cache.productData[state.cache.productCodes[escapedProductCode]]
                : null
    ));
}
