import clsx from 'clsx';
import React, {CSSProperties} from 'react';

import classes from './index.module.scss';

export interface SpinnerProps {
    size?: 'small' | 'medium' | 'large',
    variant?: 'component' | 'page',
    className?: string,
    progress?: number,
    style?: CSSProperties,
}

function Spinner({size, style, variant, className, progress}: SpinnerProps) {
    const spinner = (
        <div
            className={clsx(classes['Spinner'], {
                [classes[`Spinner--${size}`]]: size,
                [classes['Spinner--svg']]: typeof progress === 'number',
            }, className)}
            style={style}
        >
            {typeof progress === 'number' ? (
                <svg viewBox="0 0 60 60">
                    <circle cx="30" cy="30" r="20" />
                    <circle cx="30" cy="30" r="20" style={{'--progress': progress} as CSSProperties} />
                </svg>
            ) : null}
        </div>
    );
    if (variant === 'page') {
        return (
            <div className={clsx(classes['SpinnerPage'], className)}>
                {spinner}
            </div>
        );
    }
    else {
        return spinner;
    }
}

export default React.memo(Spinner);
