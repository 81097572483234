import {T_RTDBUpdate} from '@agmedia/firebase-browser-helpers';
import {createModel} from '@rematch/core';

import {RootModel} from '..';

interface SyncState {
    Update: T_RTDBUpdate,
}

const defaultState: SyncState = {
    Update: {},
};

export const sync = createModel<RootModel>()({
    state: defaultState,
    effects: {},
    reducers: {
        merge: (state, payload: T_RTDBUpdate) => {
            for (const [path, value] of Object.entries(payload || {})) {
                state.Update[path] = value;
            }
        },
        updated: (state, payload: T_RTDBUpdate) => {
            for (const key of Object.keys(payload || {})) {
                delete state.Update[key];
            }
        },
    },
});
