import {ComputedProductData} from '@vantix/functions/isomorphic/data';
import {
    T_DB_CheckoutStationConfig,
    T_DB_CheckoutStationSale,
    T_DB_ComputedSharedDeviceUser,
    T_DB_RBACSharedDevicePermission,
    T_DB_StockReception,
    T_DB_Supplier,
    T_DB_SupplierID,
    T_DB_UserID,
} from '@vantix/rtdb-rules/default';
import Dexie, {type EntityTable} from 'dexie';

export interface SharedDeviceData {
    SharedDeviceID: string,
    ERPClientID?: string,
    ShopID?: string,

    Users?: Record<string, T_DB_ComputedSharedDeviceUser>,
    RBAC?: Record<T_DB_RBACSharedDevicePermission, Record<T_DB_UserID, true>>,
    Suppliers?: Record<T_DB_SupplierID, T_DB_Supplier>,

    CheckoutStationConfig?: T_DB_CheckoutStationConfig,

    ProductDataModified?: number,
}

const db = new Dexie('Vantix') as Dexie & {
    sharedDevices: EntityTable<SharedDeviceData, 'SharedDeviceID'>,

    productData: EntityTable<ComputedProductData['ByID'][string], 'ID'>,
    productCodes: EntityTable<{Code: string; ProductID: string}, 'Code'>,

    orders: EntityTable<T_DB_CheckoutStationSale & {
        meta?: {
            sync?: '1' | undefined,
        },
    }, 'ID'>,

    stockReceptions: EntityTable<T_DB_StockReception & {
        meta?: {
            sync?: '1' | undefined,
        },
    }, 'ID'>,
};

db.version(1).stores({
    orders: 'ID,Created,Status,meta.sync',
    productData: 'ID',
    productCodes: 'Code',
    sharedDevices: 'SharedDeviceID',
    stockReceptions: 'ID,Created,Status,meta.sync',
});

export {db};
