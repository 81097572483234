import createQueuePlugin from '@agmedia/rematch-queue-plugin';
import {RematchDispatch, RematchRootState, init} from '@rematch/core';
import immerPlugin from '@rematch/immer';
import {createContext} from 'react';
import {createSelectorHook} from 'react-redux';
import {combineReducers} from 'redux';
import {enableBatching} from 'redux-batched-actions';

import {RootModel, models} from './redux';
import {IDBCacheModel, idbCacheModels} from './redux/idbcache';

const store = init<RootModel>({
    models,
    plugins: [
        immerPlugin({
            whitelist: ['checkout', 'shop', 'sync'],
        }),
    ],
    redux: {
        combineReducers: (reducers) => (
            enableBatching(combineReducers(reducers))
        ),
    },
});

export const idbCache = init<IDBCacheModel>({
    models: idbCacheModels,
    plugins: [
        immerPlugin(),
        createQueuePlugin({
            timeout: 50,
        }),
    ],
});
export const idbCacheContext = createContext(null);
export const useIDBCache = createSelectorHook<RematchRootState<IDBCacheModel>>(idbCacheContext);

export default store;

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;
