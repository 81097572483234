import {ComponentType, LazyExoticComponent, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import ConsoleFactory from 'Console';

const Console = ConsoleFactory('BrowserTools');

const bodyScrollInfo: {
    disabled?: boolean,
    scrollX?: number,
    scrollY?: number,
} = {};

export function disableBodyScroll(): void {
    if (bodyScrollInfo.disabled) {
        return;
    }

    bodyScrollInfo.scrollX = window.scrollX;
    bodyScrollInfo.scrollY = window.scrollY;

    const app = document.querySelector('#root') as HTMLDivElement;
    app.classList.add('scrollDisabled');

    app.scroll({
        top: bodyScrollInfo.scrollY,
        left: bodyScrollInfo.scrollX,
    });

    bodyScrollInfo.disabled = true;
}
export function enableBodyScroll(scrollY?: number): void {
    if (!bodyScrollInfo.disabled) {
        return;
    }

    const app = document.querySelector('#root');

    app.classList.remove('scrollDisabled');

    window.scroll({
        top: typeof scrollY === 'number' ? scrollY : bodyScrollInfo.scrollY,
        left: bodyScrollInfo.scrollX,
    });

    bodyScrollInfo.disabled = false;
}
export function setBodyOverscrollY(overscroll: boolean): void {
    // TODO: Solution for iOS when available
    // https://bugs.webkit.org/show_bug.cgi?id=176454
    // https://developer.mozilla.org/en-US/docs/Web/CSS/overscroll-behavior#browser_compatibility
    const body = document.querySelector('body');

    body.style.overscrollBehaviorY = overscroll ? null : 'contain';
}

export function useWindowSize(): {width?: number; height?: number} {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
}

export function isMobileDevice(): boolean {
    return window.navigator.userAgent.toLowerCase().includes('mobi');
}

export function useCallbackOnIdle<T>(callback: () => Promise<T>, condition?: boolean): void {
    const handle = useRef<number>();

    useEffect(() => {
        if (condition !== true) {
            return;
        }

        handle.current = window.requestIdleCallback?.(async () => {
            try {
                await callback();
            }
            catch (error) {
                Console.error('Failed to callbackOnIdle', error);
            }
        });

        return () => {
            window.cancelIdleCallback?.(handle.current);
        };
    }, [callback, condition]);
}

export function useReactImportOnIdle<T extends ComponentType>(lazyComponent: LazyExoticComponent<T>, condition?: boolean): void {
    useCallbackOnIdle(() => typeof lazyComponent._payload?._result === 'function' ? lazyComponent._payload._result() : null, condition);
}

export function useImportTranslationOnIdle(namespaces: string[], condition?: boolean): void {
    const {i18n} = useTranslation();

    useCallbackOnIdle(() => i18n.loadNamespaces(namespaces), condition);
}


export function getUA(): string {
    if (
        window.navigator !== undefined
        && typeof window.navigator['userAgent'] === 'string'
    ) {
        return window.navigator['userAgent'];
    }
    else {
        return '';
    }
}

export function isFirefox(ua = getUA()): boolean {
    return /firefox\//i.test(ua);
}

export function getScrollbarWidth(): number {
    const outer = document.createElement('div');
    outer.style.position = 'absolute';
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';
    document.body.append(outer);

    const inner = document.createElement('div');
    outer.append(inner);

    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    outer.remove();

    return scrollbarWidth;
}
