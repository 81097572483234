import basex from 'base-x';

const firebaseEncoder = basex('-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz');
const base32 = basex('0123456789ABCDEFGHJKMNPQRSTVWXYZ');

export function encodeRTDBKey(data: Uint8Array): string {
    return firebaseEncoder.encode(data);
}

export function decodeRTDBKey(string: string): Uint8Array {
    return firebaseEncoder.decode(string);
}

const timestampEncodingDataView = new DataView(new ArrayBuffer(8));
export function encodeRTDBNumber(timestamp: number | Date): string {
    timestampEncodingDataView.setBigInt64(0, BigInt(typeof timestamp === 'number' ? timestamp : timestamp.getTime()), false);

    const outputBuffer = new Uint8Array(timestampEncodingDataView.buffer);

    let nonZeroIndex = 0;
    for (let byteIndex = 0; byteIndex < 8; byteIndex++) {
        if (outputBuffer[byteIndex] === 0) {
            nonZeroIndex++;
        }
        else {
            break;
        }
    }

    return encodeRTDBKey(outputBuffer.slice(nonZeroIndex));
}

const timestampDecodingBuffer = new Uint8Array(8);
const timestampDecodingDataView = new DataView(timestampDecodingBuffer.buffer);
export function decodeRTDBNumber(string: string): number {
    if (!string) {
        return;
    }

    const decodedBuffer = (decodeRTDBKey(string));

    if (decodedBuffer.length < 8) {
        timestampDecodingBuffer.set(new Uint8Array(8 - decodedBuffer.length), 0);
    }
    timestampDecodingBuffer.set(decodedBuffer, 8 - decodedBuffer.length);

    return Number(timestampDecodingDataView.getBigInt64(0, false));
}

export function encodeEasyToWrite(input: Uint8Array): string {
    const output = [];

    const encoded = base32.encode(input);

    for (const [index, char] of [...encoded].entries()) {
        output.push(char);

        if (index % 6 === 5) {
            output.push('-');
        }
    }

    return output.join('');
}
export function decodeEasyToWrite(input: string): Uint8Array {
    return base32.decode((input || '').replaceAll(/[\s-]/g, '').toUpperCase());
}
