import {createModel} from '@rematch/core';
import {T_DB_UserInfo, T_DB_UserSettings} from '@vantix/rtdb-rules/default';

import {RootModel} from '../';

type UserState = T_DB_UserInfo & {
    loading: boolean,
    settings: T_DB_UserSettings,
};

export const user = createModel<RootModel>()({
    state: null as UserState,
    effects: {},
    reducers: {
        set: (state, payload) => {
            return {
                ...payload,
            };
        },
        setSettings: (state, payload) => {
            return {
                ...state,
                settings: {...payload},
            };
        },
        clear: () => {
            return null;
        },
    },
});
