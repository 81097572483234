import {T_RTDBUpdate} from '@agmedia/firebase-isomorphic-helpers';
import {
    P_ProductData_Compute_Patches,
    P_ProductData_Source_Shop,
    T_DB_ProductCodeType,
    T_DB_ShopProduct,
    T_DB_Unit,
} from '@vantix/rtdb-rules/default';
import BigNumber from 'bignumber.js';
import {dequal} from 'dequal';

import {RTDB, addToUpdateObject} from 'project-rtdb';

import {encodeRTDBKey, encodeRTDBNumber} from '../firebase';
import {calculatePriceWithoutVAT} from '../products';

export function getEscapedProductCode(code: string) {
    if (!code || typeof code !== 'string') {
        return null;
    }

    let escapedCode = code.replaceAll(/\s/g, '');

    if ([...escapedCode].some(char => Number.isNaN(Number(char)))) {
        escapedCode = encodeRTDBKey(Uint8Array.from([...escapedCode].map(char => char.charCodeAt(0))));
    }

    return escapedCode;
}

export function formatProductCode(code: string) {
    if (!code || typeof code !== 'string') {
        return null;
    }

    const formattedCode = code.trim();

    if ([...formattedCode].some(char => Number.isNaN(Number(char)))) {
        return formattedCode;
    }
    if (formattedCode.length === 13) {
        return `${formattedCode[0]} ${formattedCode.slice(1, 7)} ${formattedCode.slice(7, 13)}`;
    }
    if (formattedCode.length === 8) {
        return `${formattedCode.slice(0, 4)} ${formattedCode.slice(4, 8)}`;
    }

    return formattedCode;
}

export function computeProductUpdate({ShopID, ProductID, CurrentProduct, data}: {
    ShopID: string,
    ProductID: string,
    CurrentProduct?: T_DB_ShopProduct,
    data: Partial<{
        Name: string,
        Category: string,
        Code: {
            Value: string,
            Type: T_DB_ProductCodeType,
        },
        PriceWithVAT: string,
        VATRate: string,
        Unit: T_DB_Unit,
        Disabled: boolean,
        UnitDecimals: string,
        RequiresPurchaseOf: Record<string, string>,
    }>,
}): T_RTDBUpdate {
    const Update = {};

    const calculatedPrice = calculatePriceWithoutVAT(BigNumber(data.PriceWithVAT).decimalPlaces(2), data.VATRate).decimalPlaces(6);

    const Product: T_DB_ShopProduct = {
        ID: ProductID,
        Name: ((data.Name ?? CurrentProduct?.Name) || '').trim(),
        Created: CurrentProduct?.Created || encodeRTDBNumber(Date.now()),
        Category: data.Category !== undefined ? data.Category : CurrentProduct?.Category,
        Price: calculatedPrice.isNaN() ? CurrentProduct?.Price : calculatedPrice.toString(),
        VATRate: data.VATRate ?? CurrentProduct?.VATRate,
        Unit: data.Unit ?? CurrentProduct?.Unit,
        UnitDecimals: data.UnitDecimals ?? CurrentProduct?.UnitDecimals,
        Disabled: (data.Disabled !== undefined ? data.Disabled : CurrentProduct?.Disabled) || null,
        RequiresPurchaseOf: {
            ...CurrentProduct?.RequiresPurchaseOf,
            ...data.RequiresPurchaseOf,
        },
        Code: (
            data.Code?.Value === null
                ? null
                : data.Code?.Value !== undefined
                    ? data.Code
                    : CurrentProduct?.Code
        ) || null,
    };
    if (!Object.values(Product.RequiresPurchaseOf).some(value => value !== null)) {
        Product.RequiresPurchaseOf = null;
    }

    const Patch: Partial<T_DB_ShopProduct> = {};

    for (const key of Object.keys(Product)) {
        if (!dequal(Product[key], CurrentProduct?.[key] ?? null)) {
            addToUpdateObject(Update, [...P_ProductData_Source_Shop, ShopID, 'Product', ProductID, key], Product[key]);

            Patch[key] = Product[key];
        }
    }

    if (Object.keys(Patch).length > 0) {
        const newPatchID = RTDB.newKey([...P_ProductData_Compute_Patches, ShopID]);

        addToUpdateObject(Update, [...P_ProductData_Compute_Patches, ShopID, newPatchID], {
            Modified: encodeRTDBNumber(Date.now()),
            ProductID,
            Patch,
        });
    }

    return Update;
}
