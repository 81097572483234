import {ConfigProvider} from 'antd';
import {ReactNode, Suspense} from 'react';

import {PageLoader, PageLoaderRenderer} from './components/PageLoader';
import {WatchAuth, WatchFeatureFlags, WatchLanguage, WatchOnline, WatchRBAC} from './utils/app';

export default function App({children}: {children: ReactNode}) {
    return (
        <ConfigProvider
            theme={{
                token: {
                    fontSize: 16,
                },
            }}
        >
            <WatchOnline />
            <WatchAuth />
            <WatchFeatureFlags />
            <WatchRBAC />
            <WatchLanguage />
            {/* <NativeAppCommunicator /> */}

            <PageLoaderRenderer />
            {/* <RequestNotificationPermission /> */}
            <Suspense fallback={<PageLoader />}>
                {children}
            </Suspense>
        </ConfigProvider>
    );
}
