import BigNumber from 'bignumber.js';

export function calculatePriceWithVAT(priceWithoutVAT: BigNumber.Value, vatRate: BigNumber.Value): BigNumber {
    return BigNumber(priceWithoutVAT).multipliedBy(BigNumber(vatRate).dividedBy('100').plus('1'));
}

export function calculatePriceWithoutVAT(priceWithVAT: BigNumber.Value, vatRate: BigNumber.Value): BigNumber {
    return BigNumber(priceWithVAT).dividedBy(BigNumber(vatRate).dividedBy('100').plus('1'));
}

export function calculateVAT(priceWithoutVAT: BigNumber.Value, vatRate: BigNumber.Value): BigNumber {
    return BigNumber(priceWithoutVAT).multipliedBy(BigNumber(vatRate).dividedBy('100'));
}

export function calculateUnitGrossProfit({UnitAcquisitionPrice, UnitSalePrice, VATRateAcquisition}: {UnitSalePrice: BigNumber.Value; VATRateAcquisition: BigNumber.Value; UnitAcquisitionPrice: BigNumber.Value}): BigNumber {
    return calculatePriceWithoutVAT(UnitSalePrice, VATRateAcquisition).minus(UnitAcquisitionPrice);
}

export function calculateUnitPercentGrossProfit({UnitAcquisitionPrice, UnitSalePrice, VATRateAcquisition}: {UnitSalePrice: BigNumber.Value; VATRateAcquisition: BigNumber.Value; UnitAcquisitionPrice: BigNumber.Value}): BigNumber {
    return calculateUnitGrossProfit({UnitAcquisitionPrice, UnitSalePrice, VATRateAcquisition}).dividedBy(UnitAcquisitionPrice);
}
