import {P_FeatureFlags_ForAll, P_FeatureFlags_ForUsers, P_RBAC_Computed_ByUser, P_Users_Info, P_Users_Settings} from '@vantix/rtdb-rules/default';
import {getAuth} from 'firebase/auth';
import {getDatabase, onValue, ref} from 'firebase/database';
import {useEffect, useLayoutEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {shallowEqual, useSelector} from 'react-redux';

import {useRTDBLoaded, useRTDBSubscription} from 'project-rtdb';
import store from 'project-store';

import {getSharedDeviceApp} from '../components/SharedDevice/shared-device-rtdb';

import {createUser, onAuthStateChanged} from './Auth';

export function WatchSharedDeviceAuth() {
    useEffect(() => {
        const cancelWatch = getAuth(getSharedDeviceApp()).onAuthStateChanged((user) => {
            store.dispatch.generics.update({
                SharedDeviceID: user?.uid || null,
                SharedDeviceIDLoading: false,
            });
        });

        return () => {
            cancelWatch();
        };
    }, []);

    return null;
}

export function WatchAuth() {
    const {authUserID, preventUserLoad} = useSelector(state => state.generics, shallowEqual);
    const user = useRTDBSubscription([...P_Users_Info, authUserID]);
    const rtdbUserLoaded = useRTDBLoaded([...P_Users_Info, authUserID]);

    const settings = useRTDBSubscription([...P_Users_Settings, authUserID]);

    useLayoutEffect(() => {
        if (rtdbUserLoaded && !user?.ID) {
            createUser().catch(() => {});
            return;
        }

        store.dispatch.generics.update({
            loadingUser: !rtdbUserLoaded,
        });

        if (rtdbUserLoaded) {
            store.dispatch.user.set(user);
        }
    }, [user, rtdbUserLoaded]);
    useEffect(() => {
        store.dispatch.user.setSettings(settings);
    }, [settings]);

    useEffect(() => {
        if (preventUserLoad) {
            return;
        }

        return onAuthStateChanged((user) => {
            if (user?.uid) {
                store.dispatch.generics.update({
                    loadingUser: true,
                    authUserID: user.uid,
                });
            }
            else {
                store.dispatch.user.clear();

                store.dispatch.generics.update({
                    loadingUser: false,
                    authUserID: null,
                });
            }
        });
    }, [preventUserLoad]);

    return null;
}

export function WatchFeatureFlags() {
    const UserID = useSelector(state => state.user?.ID);

    const FeaturesForAll = useRTDBSubscription([...P_FeatureFlags_ForAll]);
    const FeaturesForMe = useRTDBSubscription([...P_FeatureFlags_ForUsers, UserID]);

    useEffect(() => {
        store.dispatch.featureFlags.update({
            ...FeaturesForAll,
            ...FeaturesForMe,
        });
    }, [FeaturesForAll, FeaturesForMe]);

    useEffect(() => {
        store.dispatch.featureFlags.reset();

        store.dispatch.featureFlags.update({
            ...FeaturesForAll,
            ...FeaturesForMe,
        });
    }, [UserID]);

    return null;
}

export function WatchRBAC() {
    const UserID = useSelector(state => state.user?.ID);
    const myRBAC = useRTDBSubscription([...P_RBAC_Computed_ByUser, UserID]);

    useEffect(() => {
        store.dispatch.rbac.update({...myRBAC});
    }, [myRBAC]);

    useEffect(() => {
        store.dispatch.rbac.reset();

        store.dispatch.rbac.update({...myRBAC});
    }, [UserID]);

    return null;
}

export function WatchLanguage() {
    const {i18n} = useTranslation();
    const User = useSelector(state => state.user);

    useEffect(() => {
        const lang = User?.Language || 'ro';
        if (i18n.language !== lang) {
            i18n.changeLanguage(lang);
        }
    }, [User?.Language]);

    return null;
}

export function WatchOnline() {
    useEffect(() => {
        const unsubscribe = onValue(ref(getDatabase(), '.info'), (snap) => {
            store.dispatch.generics.update({
                isOffline: !snap.val()?.connected,
            });
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return null;
}
