import {createModel} from '@rematch/core';
import {T_MINUTE} from '@vantix/functions/isomorphic/generics';

import {RootModel} from '..';

export const FISCAL_OPS_LOGIN_RECONFIRM_TIMEOUT = 1 * T_MINUTE;

interface CheckoutState {
    orderID: string | null,
    focusedProductKey: string,

    productSearchModalOpen: boolean,
    quantityModalOpen: boolean,
    settingsModalOpen: boolean,
    fiscalOpsModalOpen: boolean,

    fiscalOpsLoginReconfirm: number,
    refreshSerial: number,

    writeBuffer: string,
    multiplier: string,

    pluError: 'not-found' | 'disabled' | '',
}

const defaultState: CheckoutState = {
    orderID: null,
    focusedProductKey: '',

    productSearchModalOpen: false,
    quantityModalOpen: false,
    settingsModalOpen: false,
    fiscalOpsModalOpen: false,

    fiscalOpsLoginReconfirm: 0,
    refreshSerial: 0,

    writeBuffer: '',
    multiplier: '',

    pluError: '',
};

export const checkout = createModel<RootModel>()({
    state: defaultState,
    effects: {},
    reducers: {
        update: (state, payload: Partial<CheckoutState>) => {
            for (const [key, value] of Object.entries(payload)) {
                state[key] = value;
            }
        },
    },
});
