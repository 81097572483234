import {Models, createModel} from '@rematch/core';
import {T_DB_ShopProduct} from '@vantix/rtdb-rules/default';

export interface IDBCacheType {
    productData: Record<string, T_DB_ShopProduct>,
    productCodes: Record<string, string>,
}

const initialState: IDBCacheType = {
    productData: {},
    productCodes: {},
};

export const cache = createModel<IDBCacheModel>()({
    state: initialState,
    effects: {},
    reducers: {
        update(state, update: Partial<IDBCacheType>) {
            for (const cacheType of Object.keys(update || {})) {
                for (const [key, value] of Object.entries(update[cacheType] || {})) {
                    state[cacheType][key] = value;
                }
            }
        },
    },
});


export interface IDBCacheModel extends Models<IDBCacheModel> {
    cache: typeof cache,
}

export const idbCacheModels: IDBCacheModel = {
    cache,
};
