import {handleRuntimeError as origHandleRuntimeError} from '@pmmmwh/react-refresh-webpack-plugin/overlay';

export {
    clearRuntimeErrors,
    clearCompileError,
    showCompileError,
    showRuntimeErrors,
} from '@pmmmwh/react-refresh-webpack-plugin/overlay';

const symbol = Symbol('overlay');

window.preventOverlay = function(error) {
    error[symbol] = false;
};

export function handleRuntimeError(error) {
    setTimeout(() => {
        if (error?.[symbol] === false) {
            return;
        }

        origHandleRuntimeError(error);
    });
}
